<template>
  <div class="popub" v-if='show && type == 2'>
    <div class="popub-box pay-wk-width">
      <div class="popub-inbox pay-wk">
        <div class="h3">尾款支付</div>
        <img
          @click="close"
          class="pop-close"
          src="../../../../assets/images/common_iocn_guanbi.png"
          alt=""
        />
    
        <div class="wk-box">
          <p class="pop-label">
            尾款金额
          </p>
          <p class="wk-je">
                  {{last_pay}}<span>元</span>
                </p>
        </div>
         <div class="pay-type">
          <label class="pop-label">付款方式</label>
          <div class="radio-box cf">
            <el-radio v-model="pay_type" label="1" @change="radioChange"
              >微信支付</el-radio
            >
            <el-radio v-model="pay_type" label="2" @change="radioChange"
              >支付宝支付</el-radio
            >
            <el-radio v-model="pay_type" label="3" @change="radioChange"
              >线下打款</el-radio
            >
          </div>
        </div>
        <img  v-if="pay_type != 3" class="code-img" :src="codeImg" alt="" />
        <el-upload
              v-else
              v-loading="loading"
              class="upload-demo"
              :action="action"
              :before-upload='begin'
              :data="upData"
              :name="name"
              :headers="headers"
              :limit="1"
              :on-success="upPz"
              :show-file-list="false"
            >
              <div class="up-btn" v-if="img_url == ''">
                <img src="../.././../../assets/images/comon_iocn_tpsc.png" alt="">
                <p>上传打款凭证</p>
              </div>
              <img class="up-img" v-else :src="img_url" alt="">
            </el-upload>
        <p class="hint">
          {{
            pay_type == 1
              ? "微信扫描转款"
              : pay_type == 2
              ? "支付宝扫描转款"
              : pay_message
          }}
        </p>
        <button class="pay-btn" @click="payOrder">支付尾款</button>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderAmonut:{
        type:String,
        default:'0'
    },
    orderId:{
         type:String,
        default:'0' 
    },
    type:{
        type:Number,
        default:2
    }
  },
};
</script>
<style scoped>
@import "../style.css";
</style>