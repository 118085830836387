export default {
    data() {
        return {
            time_level: 1,
            quality_level: 1,
            cost_level: 1,
            service_level: 1,
            time_text: '极差',
            quality_text: '极差',
            cost_text: '极差',
            service_text: '极差',
        }
    },
    methods: {
        selStar: function (type, index) {
            console.log(index);
            let text = this.pjText(index);
            switch (type) {
                case 1:
                    this.time_level = index + 1;
                    this.time_text = text;
                    break;
                case 2:
                    this.quality_level = index + 1;
                    this.quality_text = text;
                    break;
                case 3:
                    this.cost_level = index + 1;
                    this.cost_text = text;
                    break;
                default:
                    this.service_level = index + 1;
                    this.service_text = text;
                    break;
            }
        },
        pjText(index) {
            switch (index) {
                case 0:
                    return '极差';
                    break;
                case 1:
                    return '一般';
                    break;
                case 2:
                    return '一般';
                    break;
                case 3:
                    return '好';
                    break;
                default:
                    return '非常好';
                    break;
            }
        }
    }
}