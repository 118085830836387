export default{
    data(){
        return{
            mark:''
        }
    },
    created(){

    },
    methods:{
        close:function(){
            this.$emit('closepop');
        },
        applyAfterSales:function(){
            let formData={
                token:true,
                mark:this.mark,
                id:this.orderId	
            }
            this.$post('/api/order/applyAfterSales.html', formData).then((res) => {
                if (res.code == 200) {
                    setTimeout(() => {
                        this.mark = ''
                        this.$emit('closepop');
                    }, 1000);
                } else {
                
                }
                return this.$message(res.msg);
            }).finally(() => {

            }) 
        }
    }
}