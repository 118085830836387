export default{
    data(){
        return{
            action: this.url + '/api/base/uploadPayImg.html',
            fileList: [],
            name: "upfile",
            headers: {
                // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
            },
            upData: {
                token: localStorage['yhtoken'],
            },
            img_url:'',
            loading:false,
        }
    },
    methods:{
        begin:function(){
            console.log(123)
            this.loading = true;
        },
        upPz:function(res){
            this.loading = false
            this.img_url = res.data.url || ''
        }
    }
}