export default{
    data(){
        return{
            paysn_id:'',
            codeImg:'',
            order_amonut:'',
            need_pay:'',
            is_first_pay:'',
            order_sn:'',
            ratio_b:'',
            first_pay:'',
            last_pay:'',
            pay_message:''
        }
    },
    created(){

    },
  
    methods:{
    
        orderPayDetail:function(){
            let formData ={
                token:true,
                order_id:this.orderId,
            }
            this.$post('/api/order/orderPayDetail.html', formData).then((res) => {
                if (res.code == 200) {
                 this.order_amonut = res.data.order_amonut;
                 this.need_pay = res.data.need_pay
                 this.is_first_pay = res.data.is_first_pay;
                 this.order_sn = res.data.order_sn;
                 this.ratio_b = res.data.ratio_b;
                 this.last_pay = res.data.last_pay;
                 this.pay_message = res.data.pay_message;
                 this.wxOrderPay(this.orderId,res.data.order_amonut)
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        wxOrderPay:function(order_id){
            let formData ={
                token:true,
                order_id:order_id,
            }
            this.$post('/api/order/wxPayCode.html', formData).then((res) => {
                if (res.code == 200) {
                   this.paysn_id = res.data.paysn_id || '';
                   this. codeImg = res.data.url || '';
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        alipayPayCode:function(order_id){
            let formData ={
                token:true,
                order_id:order_id,
            }
            this.$post('/api/order/alipayPayCode.html', formData).then((res) => {
               /* if (res.code == 200) {
                   //this.paysn_id = res.data.paysn_id || '';
                   //this. codeImg = res.data.url || '';
                    console.log(res.data);
                } else {
                    return this.$message(res.msg);
                }*/
              /*  document.querySelector('body').innerHTML = res;  //查找到当前页面的body，将后台返回的form替换掉他的内容
                document.forms[0].submit();*/
                let dwSafari
                dwSafari=window.open();
                dwSafari.document.open();
                let dataObj=res
                dwSafari.document.write("<html><head><title></title><meta charset='utf-8'><body>"+dataObj+"</body></html>")
                dwSafari.document.forms[0].submit()
                dwSafari.document.close()
            }).finally(() => {

            })
        }
    }
}