<template>
  <div class="popub" v-if="show && type == 1">
    <div class="popub-box pay-box">
      <div class="popub-inbox pay">
        <div class="h3">付款</div>
        <img
          @click="close"
          class="pop-close"
          src="../../../../assets/images/common_iocn_guanbi.png"
          alt=""
        />
        <div class="inp-list cf">
          <div class="inp-item fl">
            <label class="pop-label">收货人</label>
            <input type="text" v-model="customer" placeholder="输入收货人" />
          </div>
          <div class="inp-item fr">
            <label class="pop-label">收货人联系方式</label>
            <input type="text"  v-model="customer_phone"  placeholder="输入收货人联系方式" />
          </div>
          <div class="inp-item fl">
            <label class="pop-label">收货地址</label>
            <input type="text"  v-model="customer_addr"  placeholder="输入收货地址" />
          </div>
        </div>
        <div class="dj-box">
          <p class="pop-label">
            支付订金 <span class="dj-je">（共{{ order_amonut }}元）</span>
          </p>
          <p class="dj-bl">{{ ratio_b }}（需支付{{ first_pay }}元）</p>
        </div>
        <div class="pay-type">
          <label class="pop-label">付款方式</label>
          <div class="radio-box cf">
            <el-radio v-model="pay_type" label="1" @change="radioChange"
              >微信支付</el-radio
            >
            <el-radio v-model="pay_type" label="2" @change="radioChange"
              >支付宝支付</el-radio
            >
            <el-radio v-model="pay_type" label="3" @change="radioChange"
              >线下打款</el-radio
            >
          </div>
        </div>
        <img   v-loading="loading"  v-if="pay_type != 3" class="code-img" :src="codeImg" alt="" />
        <el-upload
              v-else
              v-loading="loading"
              class="upload-demo"
              :action="action"
              :data="upData"
              :name="name"
              :headers="headers"
              :limit="1"
              :before-upload='begin'
              :on-success="upPz"
              :show-file-list="false"
            >
              <div class="up-btn" v-if="img_url == ''">
                <img src="../.././../../assets/images/comon_iocn_tpsc.png" alt="">
                <p>上传打款凭证</p>
              </div>
              <img class="up-img" v-else :src="img_url" alt="">
            </el-upload>
        <p class="hint">
          {{
            pay_type == 1
              ? "微信扫描转款"
              : pay_type == 2
              ? "支付宝扫描转款"
              : pay_message
          }}
        </p>
        <button class="pay-btn" @click="payOrder">完成支付</button>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: "0",
    },
    type: {
      type: Number,
      default: 1,
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>