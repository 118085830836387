export default{
    data(){
        return{
            pay_type:'1',
            customer:'',
            customer_phone:'',
            customer_addr:'',
            img_url:''
        }
    },
    created(){

    },
    watch:{
        show:function(val){
            if(val && this.type == 1){
               this.orderPayDetail(); 
            }
          
        }
    },
    methods:{
        radioChange:function(val){
            if(val == 1){
                this.wxOrderPay(this.orderId)

            }else if(val == 2){
                this.alipayPayCode(this.orderId);
            }
        },
        close:function(){
            this.$emit('closepop');
        },
        payOrder:function(){
            if(this.pay_type == 3){
                if(this.img_url == '' || this.customer ==''|| this.customer_phone=='' || this.customer_addr == ''){
                    return this.$message('请填写完整的付款信息');
                }
            }else{
                if(this.customer ==''|| this.customer_phone=='' || this.customer_addr == ''){
                    return this.$message('请填写完整的付款信息');
                }  
            }
            let formData ={
                token:true,
                pay_type:this.pay_type,
                order_id:this.orderId,
                pay_amount:this.order_amonut,
                // pay_amount:0.01,
                paysn_id:this.paysn_id,
                customer:this.customer,
                customer_phone:this.customer_phone,
                customer_addr:this.customer_addr,
                img_url:this.img_url
            }
            this.$post('/api/order/payOrder.html', formData).then((res) => {
                if (res.code == 200) {
                    this.close();
                    return this.$message('支付成功');
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            }) 
        }
    }
}