<template>
    <div class="popub" v-if="show && type ==9">
        <div class="popub-box">
            <div class="popub-inbox pay">
                <div class="h3">发起售后</div>
                <img class="pop-close" src="../../../../assets/images/common_iocn_guanbi.png" alt="" />
                <div class="inp-list cf">
                    <div class="inp-item fl">
                        <label>收货人</label>
                        <input type="text" placeholder="输入收货人">
                    </div>
                     <div class="inp-item fr">
                        <label>收货人联系方式</label>
                        <input type="text" placeholder="输入收货人联系方式">
                    </div>
                     <div class="inp-item fl">
                        <label>收货地址</label>
                        <input type="text" placeholder="输入收货地址">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: "0",
    },
    type: {
      type: Number,
      default: 1,
    },
  },
};
</script>
<style scoped>
@import '../style.css';
</style>