<template>
  <div class="popub" v-if="show && type == 3">
    <div class="popub-box after-sale-w">
      <div class="popub-inbox af-sale">
        <div class="h3">发起售后</div>
        <img
          class="pop-close"
          @click="close"
          src="../../../../assets/images/common_iocn_guanbi.png"
          alt=""
        />
        <textarea placeholder="请输入原因" name="" id="" cols="30" rows="10" v-model="mark"></textarea>
        <button class="btn" @click="applyAfterSales">提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderAmonut: {
      type: String,
      default: "0",
    },
    orderId: {
      type: String,
      default: "0",
    },
    type: {
      type: Number,
      default: 2,
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>