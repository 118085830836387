<template>
  <div class="popub" v-if="show && type == 4">
    <div class="popub-box eval-w">
      <div class="popub-inbox">
        <div class="h3">订单评价</div>
        <img
          class="pop-close"
          @click="close"
          src="../../../../assets/images/common_iocn_guanbi.png"
          alt=""
        />
        <div class="pj-top cf">
          <!-- <p class="fl top-item">订单名称：</p> -->
          <p class="fl top-item">订单编号：{{orderNum}}</p>
        </div>
        <p class="h4">本次服务体验评价</p>
        <div class="pj-list">
          <div class="pj-item cf">
            <p class="fl lable">订单实际完成时间</p>
            <div class="fl star-list">
              <img
                v-for="(item, index) in 5"
                :key="index"
                :src="index < time_level ? require('../../../../assets/images/pj_iocn_xx_s.png'):require('../../../../assets/images/pj_iocn_xx_n.png')"
                alt=""
                @click="selStar(1,index)"
              />
            </div>
            <p class="fl pj-text">({{ time_text }})</p>
          </div>
          <div class="pj-item cf">
            <p class="fl lable">产品加工质量</p>
            <div class="fl star-list">
              <img
                v-for="(item, index) in 5"
                :key="index"
                :src="index < quality_level ? require('../../../../assets/images/pj_iocn_xx_s.png'):require('../../../../assets/images/pj_iocn_xx_n.png')"
                alt=""
                @click="selStar(2,index)"
              />
            </div>
            <p class="fl pj-text">({{ quality_text }})</p>
          </div>
          <div class="pj-item cf">
            <p class="fl lable">实际支付成本</p>
            <div class="fl star-list">
              <img
                v-for="(item, index) in 5"
                :key="index"
                :src="index < cost_level ? require('../../../../assets/images/pj_iocn_xx_s.png'):require('../../../../assets/images/pj_iocn_xx_n.png')"
                alt=""
                @click="selStar(3,index)"
              />
            </div>
            <p class="fl pj-text">({{ cost_text }})</p>
          </div>
          <div class="pj-item cf">
            <p class="fl lable">平台服务人员的专业性</p>
            <div class="fl star-list">
              <img
                v-for="(item, index) in 5"
                :key="index"
                 :src="index < service_level ? require('../../../../assets/images/pj_iocn_xx_s.png'):require('../../../../assets/images/pj_iocn_xx_n.png')"
                alt=""
                @click="selStar(4,index)"
              />
            </div>
            <p class="fl pj-text">({{ service_text }})</p>
          </div>
        </div>
        <textarea
          class="text-area"
          placeholder="是不是想说点什么"
          v-model="content"
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
        <div class="up-box cf">
          <div class="photo-item fl" v-if="img_url != ''">
              <img class="photo" :src="img_url" alt="" />
              <div class="delete-box" @click="deletePhoto()">
                <img
                  class="delete-icon"
                  src="../../../../assets/images/zc_iocn_sc.png"
                  alt=""
                />
              </div>
            </div>
          <el-upload
           v-else
            class="upload-demo fl"
            :action="action"
                  :before-upload='begin'
            v-loading="loading"
            :data="upData"
            :name="name"
            :on-remove="deletePhoto"
            :headers="headers"
            :limit="1"
            :on-success="upPj"
            :show-file-list="false"
          >
            <div class="up-btn">
              <img
                src="../.././../../assets/images/comon_iocn_tpsc.png"
                alt=""
              />
              <p>上传图片</p>
            </div>
            
          </el-upload>
        </div>
        <div class="pj-hint">感谢您百忙之中对本次服务进行评价！</div>
        <button  class="pj-btn" @click="addComments">提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: "0",
    },
    type: {
      type: Number,
      default: 1,
    },
    orderNum: {
      type: String,
      default: '',
    },
    orderName: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>