export default{
    data(){
        return{
            content:'',
        }
    },
    created(){

    },
    methods:{
        close:function(){
            this.time_level = 1;
            this.quality_level =1;
            this.cost_level= 1;
            this.service_level= 1;
            this.time_text ='极差';
            this.quality_text= '极差';
            this.cost_text= '极差';
            this.service_text= '极差';
            this.content = '';
            this.$emit('closepop');
        },
        addComments:function(){
            let formData={
                order_id:this.orderId,
                time_level: this.time_level,
                quality_level: this.quality_level,
                cost_level: this.cost_level,
                service_level: this.service_level,
                content:this.content,
                img_url	:this.img_url,
                token:true	
            }
            // console.log(formData)
            this.$post('/api/user/addComments.html', formData).then((res) => {
                if (res.code == 200) {
                    this.close();
                    return this.$message('评价成功');
      
                    
                } else {
                    return this.$message(res.msg);
                }
            
            }).finally(() => {

            })
        } 
    }
}