<template>
  <div class="popub" v-if="show && type == 5">
    <div class="popub-box st-w st-w1" v-if="shType == 1">
      <div class="popub-inbox sh">
        <div class="h3">发起售后</div>
        <img
          class="pop-close"
          src="../../../../assets/images/common_iocn_guanbi.png"
          alt=""
          @click="close"
        />
        <div class="sh-tb cf">
          <p class="fl">订单编号：{{orderSn}}</p>
          <p class="fl">加工分类：{{jgType}}</p>
          <!-- <p class="fl">跟单员：</p>
          <p class="fl">技术员</p> -->
        </div>
        <div class="sh-type-box">
          <label class="pop-label">选择售后类型</label>
          <el-radio v-model="shType" label="1"
            >返工维修</el-radio
          >
        </div>
        <div class="inp-list cf">
          <div class="inp-item fl">
            <label class="pop-label">物流公司</label>
            <input type="text" placeholder="输入物流公司"  v-model="courier_company"  />
          </div>
          <div class="inp-item fr">
            <label class="pop-label">物流单号</label>
            <input type="text" placeholder="输入物流单号" v-model="courier_num"  />
          </div>
        </div>
        <button class="tj-btn" @click="doOrderAfterSales">提交</button>
      </div>
    </div>
    <div class="popub-box st-w" v-else>
      <div class="popub-inbox sh">
        <div class="h3">发起售后</div>
        <img
          class="pop-close"
          src="../../../../assets/images/common_iocn_guanbi.png"
          alt=""
          @click="close"
        />
        <div class="sh-tb cf">
         <p class="fl">订单编号：{{orderSn}}</p>
          <p class="fl">加工分类：{{jgType}}</p>
          <!-- <p class="fl">跟单员：</p>
          <p class="fl">技术员</p> -->
        </div>
        <div class="sh-type-box">
          <label class="pop-label">选择售后类型</label>
          <el-radio v-model="shType" label="2"
            >其他方式</el-radio
          >
        </div>
        <div class="inp-list cf">
          <div class="inp-item fl">
            <label class="pop-label">退款账号</label>
            <input type="text" placeholder="输入退款账号" v-model="account" />
          </div>
          <div class="inp-item fr">
            <label class="pop-label">开户行</label>
            <input type="text" placeholder="输入开户行"  v-model="bank"/>
          </div>
          <div class="inp-item fl">
            <label class="pop-label">收款人</label>
            <input type="text" placeholder="输入收款人" v-model="payee" />
          </div>
          <div class="inp-item fr">
            <label class="pop-label">收款人联系方式</label>
            <input type="text" placeholder="输入收款人联系方式"  v-model="payee_phone"/>
          </div>
          <div class="inp-item fl">
            <label class="pop-label">物流公司</label>
            <input type="text" placeholder="输入物流公司" v-model="courier_company" />
          </div>
          <div class="inp-item fr">
            <label class="pop-label">物流单号</label>
            <input type="text" placeholder="输入物流单号" v-model="courier_num" />
          </div>
        </div>
        <button class="tj-btn" @click="doOrderAfterSales">提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: "0",
    },
    type: {
      type: Number,
      default: 1,
    },
    shType: {
      type: String,
      default: "1",
    },
    orderSn: {
      type: String,
      default: "",
    },
    jgType: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
@import "../style.css";
</style>