export default{
    data(){
        return{
            courier_num:'',
            courier_company:'',
            account:'',
            bank:'',
            payee:'',
            payee_phone:'',
            token:true,
            id:''
        }
    },
    created(){

    },
    methods:{
        close:function(){
            this.$emit('closepop');
        },
        doOrderAfterSales:function(){
            if(this.shType == 2){
                if(this.account == '' || this.bank == '' || this.payee == '' || this.payee_phone == ''){
                    return this.$message('请填写完整售后信息');
                }
            }else{

            }
          
            let formData ={
                return_type:this.shType == 1? 2:3,
                courier_num : this.courier_num,
                courier_company :this.courier_company,
                account :this.account,
                bank :this.bank,
                payee:this.payee,
                payee_phone:this.payee_phone,
                token:true,
                id:this.orderId
            }
            this.$post('/api/order/doOrderAfterSales.html', formData).then((res) => {
                if (res.code == 200) {
                    setTimeout(() => {
                        this.close();
                    }, 1000);
                } else {
                  
                }  return this.$message(res.msg);
            }).finally(() => {

            })
        },
        
       
    }
}